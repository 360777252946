"use client";
import { useState, MouseEvent } from "react";
import styles from "./styles.module.css";
import { ITooltipCard } from "@/app/types";

export default function TooltipCard(props: ITooltipCard) {
  const { label, tooltip, align, forceDisplay } = props;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleOnMouseEnter = (e: MouseEvent) => {
    const width = e.currentTarget.getBoundingClientRect().width;
    const newShowTooltip = forceDisplay ? true : width < 280 ? false : true;
    setShowTooltip(newShowTooltip);
  };

  return (
    <div
      className={styles.tooltipCard}
      style={{ textAlign: align }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {label}
      {showTooltip && (
        <div className={styles.tooltipCardTooltip}>
          <p>{tooltip}</p>
        </div>
      )}
    </div>
  );
}
