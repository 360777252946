export const getRandomInt = (min: number, max: number): number => {
  // Adjust min and max to integer values
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);

  // Validate the input range
  if (minInt >= maxInt) {
    throw new Error(
      'Invalid arguments: "min" must be less than "max" after adjusting to integer bounds.',
    );
  }

  // Generate a random integer between min (inclusive) and max (exclusive)
  return Math.floor(Math.random() * (maxInt - minInt) + minInt);
};
