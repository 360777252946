"use client";
import { select, line, curveLinearClosed, scaleLinear } from "d3";
import { useEffect, useRef } from "react";

import styles from "./styles.module.css";
import { getRandomInt } from "@/app/utils/math";

export default function SpiderChart() {
  const chartRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // Check clause
    if (!chartRef.current) return;
    if (!svgRef.current) return;

    const height = 60;
    const width = height;
    const radius = width / 2;
    const scales = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const extent = 10;
    const axis = [1, 2, 3, 4, 5, 6];
    const angle = (Math.PI * 2) / axis.length;
    const radiusDelta = radius / 10;
    const angleOffset = Math.PI / 2;

    const generateRandomPath = () => {
      const radiusGrid = radiusDelta;
      const linear = line()
        .curve(curveLinearClosed)
        .x((d: any, i) => radiusGrid * d[0] * Math.cos(i * angle - angleOffset))
        .y(
          (d: any, i) => radiusGrid * d[0] * Math.sin(i * angle - angleOffset),
        );

      const points = generatePathPoints(6);
      const path = linear(points);
      return path;
    };

    // Render the chart
    const svg = select(svgRef.current)
      .data([null])
      .join("svg")
      .classed("svg_chart", true)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height]);

    // Define the scale for axis
    const axisScale = scaleLinear().range([0, radius]).domain([0, extent]);

    // Draw the axis
    const gAxis = svg
      .selectAll(".g_axis")
      .data([null])
      .join("g")
      .classed("g_axis", true)
      .attr("transform", `translate(${radius}, ${radius})`);

    gAxis
      .selectAll(".g_axis_line")
      .data(axis)
      .join("line")
      .classed("g_axis_line", true)
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", (d) => axisScale(extent) * Math.cos(angle * d - Math.PI / 2))
      .attr("y2", (d) => axisScale(extent) * Math.sin(angle * d - Math.PI / 2))
      .style("stroke", "#22222260")
      .style("stroke-width", "0.15px");

    // Draw the grids
    const gGrids = svg
      .selectAll(".g_grids")
      .data([null])
      .join("g")
      .classed("g_grids", true)
      .attr("transform", `translate(${radius}, ${radius})`);

    gGrids
      .selectAll(".g_grids_path")
      .data(scales)
      .join("path")
      .classed("g_grids_path", true)
      .attr("d", (d) => {
        const radiusGrid = d * radiusDelta;
        const linear = line()
          .curve(curveLinearClosed)
          .x((_, i) => radiusGrid * Math.cos(i * angle - angleOffset))
          .y((_, i) => radiusGrid * Math.sin(i * angle - angleOffset));

        const path = linear([
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ]);
        return path;
      })
      .style("stroke", "#22222260")
      .style("stroke-dasharray", "2 2")
      .style("stroke-width", "0.15px")
      .style("fill", "transparent");

    // Draw the spider chart
    const gSpider = svg
      .selectAll(".g_spider")
      .data([null])
      .join("g")
      .classed("g_spider", true)
      .attr("transform", `translate(${radius}, ${radius})`);

    gSpider
      .selectAll(".g_spider_path")
      .data([null])
      .join("path")
      .classed("g_spider_path_a", true)
      .attr("d", generateRandomPath())
      .style("stroke", "#0000FF")
      .style("stroke-width", "0.25px")
      .style("fill", "#0000FF")
      .style("fill-opacity", "0.2");

    // Clean up
    return () => {
      svg.selectAll("*").remove();
    };
  }, []);

  const generatePathPoints = (n: number) => {
    const arr = Array.from({ length: n }, () => getRandomInt(0, 10) + 1);
    const points = [];
    for (let i = 0; i < arr.length; i++) {
      points.push([arr[i], 0]);
    }
    return points as [number, number][];
  };

  return (
    <div className={styles.spiderChart} ref={chartRef}>
      <svg ref={svgRef}></svg>
    </div>
  );
}
